<template>
  <div id="MichouPublicConsumption">
    <QsCard :qlik-ids="['QRNejcq']" :preserves-on-destroy="['QRNejcq']" />
    <QsCard :qlik-ids="['WKzfsG']" />
    <QsCard :qlik-ids="['Ufpya']" />

    <QsCard :qlik-ids="['JtzfrC']" />
    <QsCard :qlik-ids="['zhfhXS']" style="grid-column: 2 / span 2" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";

export default { components: { QsCard } };
</script>

<style scoped>
#MichouPublicConsumption {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 300px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
